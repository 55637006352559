<template>
  <div>
    <header
      v-if="!$parent.scrolled && !isMobile"
      class="header-desktop">
      <div class="top_header">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-4/5 px-4 pt-2 text-center bg-gray-light text-right">
              <p>
                <img
                  @click="changeLanguage('en')"
                  src="~/assets/images/flags/en.png"
                  alt="English"
                  class="w-6 inline cursor-pointer"
                />
                <img
                  @click="changeLanguage('fr')"
                  src="~/assets/images/flags/fr.png"
                  alt="Français"
                  class="w-6 inline cursor-pointer"
                />
                <img
                  @click="changeLanguage('de')"
                  src="~/assets/images/flags/de.png"
                  alt="Deutsch"
                  class="w-6 inline cursor-pointer"
                />
                <img
                  @click="changeLanguage('ie')"
                  src="~/assets/images/flags/ie.png"
                  alt="Ireland"
                  class="w-6 inline cursor-pointer"
                />
              </p>
            </div>
            <div
              class="w-1/5 px-4 text-right bg-red text-white font-light text-sm skewed-corner relative"
            >
              <p>
                <font-awesome-icon :icon="['fas', 'phone']" flip="horizontal" />
                <a
                  v-html="$t('menu.phone_number_view')"
                  :href="`${$t('menu.phone_number')}`"
                  class="hover:text-black"
                /><br>
                <font-awesome-icon :icon="['fas', 'envelope']"/>
                <a
                  v-html="$t('menu.email_view')"
                  :href="`${$t('menu.email')}`"
                  class="hover:text-black"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="main_header">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-1/3 tablet:w-1/5 px-4">
              <div class="logo">
                <nuxt-link :to="localePath('index')">
                  <img
                    :alt="$t('menu.used_car_parts')"
                    src="~assets/images/logos/logo.svg"
                    style="max-height: 70px;"
                    height="70"
                    width="208"/>
                  </nuxt-link>
              </div>
            </div>
            <div class="w-2/3 tablet:w-4/5 px-4 flex flex-wrap items-center">
              <div class="w-full tablet:w-2/3 pr-6">
                <form
                  :action="localePath('search')"
                  method="get"
                  class="search-form">
                  <div class="input-group black-border w-full">
                    <form-input
                      :class="{'is-placeholder-hidden': isSearchAnimationRunning}"
                      :required="true"
                      :placeholder="
                        `${$t('components.search_box.search_like')}`
                      "
                      type="text"
                      name="q"
                      @focus="stopAnimateSearchPlaceholder"
                      @blur="searchBlurHandler"/>
                    <div
                      v-if="isSearchAnimationRunning"
                      id="animated-placeholder">
                      {{ searchAnimationTxt }}
                    </div>
                    <div class="input-group__append">
                      <button-primary
                        :icon-prop="['fas', 'search']"
                        :icon-size="'lg'"
                        type="submit-form"
                        :extra-class="'button--without-skew'">
                      </button-primary>
                    </div>
                  </div>
                </form>
              </div>
              <div class="w-full tablet:w-1/3">
                <div class="flex flex-wrap -mx-2">
                  <div
                    class="w-1/3 tablet:w-2/6 tablet:border-r tablet:border-l border-solid border-gray-medium px-2 text-center">
                    <nuxt-link
                      :to="localePath('account')"
                      class="hover:text-red" >
                      {{ $t('menu.account') }} <font-awesome-icon :icon="['fas', 'user']" class="ml-1"/>
                    </nuxt-link>
                  </div>
                  <div class="w-1/3 tablet:w-2/6 text-center tablet:border-r border-solid border-gray-medium px-2 text-center">
                    <nuxt-link :to="localePath('cart')" class="hover:text-red">
                      <font-awesome-icon :icon="['fas', 'shopping-basket']" class="ml-1"/>
                      <span
                        v-html="cartQty"
                        class="rounded-full px-2 bg-red text-white ml-1 text-sm"
                      />
                    </nuxt-link>
                  </div>
                  <div class="w-1/3 tablet:w-2/6 pl-1 text-center">
                    <nuxt-link :to="localePath('favorites')" class="hover:text-red">
                      <font-awesome-icon :icon="['fas', 'heart']" class="ml-1"/>
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu_header">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4 text-right">
              <ul>
                <li>
                  <nuxt-link :to="localePath('index')">
                    <font-awesome-icon :icon="['fas', 'home']" />
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.about_us')"
                    :to="localePath('about-us')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.products')"
                    :to="localePath('products')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('components.new_products.header')"
                    :to="localePath('new-products')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.news')"
                    :to="localePath('news')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.contact')"
                    :to="localePath('contact')"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header
      v-if="$parent.scrolled && !isMobile"
      class="header-desktop">
      <div class="main_header">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-1/3 tablet:w-1/5 px-4">
              <div class="logo pr-16">
                <nuxt-link :to="localePath('index')"
                  ><img
                    :alt="$t('menu.used_car_parts')"
                    src="~assets/images/logos/logo.svg"
                    height="64"
                    width="144"
                /></nuxt-link>
              </div>
            </div>
            <div class="w-2/3 tablet:w-4/5 px-4 flex flex-wrap items-center">
              <div class="w-full tablet:w-2/3 px-2 pr-6">
                <form
                  :action="localePath('search')"
                  method="get"
                  class="search-form">
                  <div class="input-group black-border w-full">
                    <form-input
                      :required="true"
                      :placeholder="
                        `${$t('components.search_box.search_like')}`
                      "
                      type="text"
                      name="q"/>
                    <div class="input-group__append">
                      <button-primary
                        :icon-prop="['fas', 'search']"
                        :icon-size="'lg'"
                        type="submit-form"
                        :extra-class="'button--without-skew'">
                      </button-primary>
                    </div>
                  </div>
                </form>
              </div>
              <div class="w-full tablet:w-1/3">
                <div class="flex flex-wrap -mx-2">
                  <div
                    class="w-1/3 tablet:w-2/6 tablet:border-r tablet:border-l border-solid border-gray-medium px-2 text-center"
                  >
                    <nuxt-link
                      :to="localePath('account')"
                      class="hover:text-red" >
                      {{ $t('menu.account') }} <font-awesome-icon :icon="['fas', 'user']" class="ml-1"/>
                    </nuxt-link>
                  </div>
                  <div class="w-1/3 tablet:w-2/6 tablet:border-r border-solid border-gray-medium px-2 text-center">
                    <nuxt-link
                      :to="localePath('cart')"
                      class="hover:text-red">
                      <font-awesome-icon :icon="['fas', 'shopping-basket']" class="ml-1"/>
                      <span
                        v-html="cartQty"
                        class="rounded-full px-2 bg-red text-white ml-1 text-sm"
                      />
                    </nuxt-link>
                  </div>
                  <div class="w-1/3 tablet:w-2/6 px-1 text-center">
                    <nuxt-link
                      :to="localePath('favorites')"
                      class="hover:text-red" >
                      <font-awesome-icon :icon="['fas', 'heart']" class="ml-1"/>
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu_header">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4 text-right">
              <ul>
                <li>
                  <nuxt-link :to="localePath('index')" >
                    <font-awesome-icon :icon="['fas', 'home']" />
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.about_us')"
                    :to="localePath('about-us')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.products')"
                    :to="localePath('products')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('components.new_products.header')"
                    :to="localePath('new-products')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.news')"
                    :to="localePath('news')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.contact')"
                    :to="localePath('contact')"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header
      v-if="isMobile"
      class="header-mobile">
      <div class="container pt-2">
        <div class="flex px-3">
          <nuxt-link
            :to="localePath('index')"
            class="mr-auto">
            <img
              :alt="$t('menu.used_car_parts')"
              src="~assets/images/logos/logo.svg"
              style="height: 70px; width: auto;"
              height="133"
              width="300"/>
          </nuxt-link>
          <nuxt-link
            :to="localePath('account')"
            class="ml-4 mr-2 phone:mr-4 my-auto px-2 phone:px-3 py-1 hover:text-red" >
            <font-awesome-icon :icon="['fas', 'user']"/>
          </nuxt-link>
          <nuxt-link
            :to="localePath('cart')"
            class="mx-2 phone:mx-4 my-auto px-2 phone:px-3 py-1 hover:text-red flex" >
            <font-awesome-icon :icon="['fas', 'shopping-basket']" class="my-auto" />
            <span
              v-html="cartQty"
              class="rounded-full px-2 ml-1 bg-red my-auto text-white text-sm"
            />
          </nuxt-link>
          <nuxt-link
            :to="localePath('favorites')"
            class="mx-2 phone:mx-4 my-auto px-2 phone:px-3 py-1 hover:text-red" >
            <font-awesome-icon :icon="['fas', 'heart']"/>
          </nuxt-link>
          <div
            :class="{
              open: showMenu,
              'hamburger flex-shrink-0 ml-2 phone:ml-4 my-auto py-1': true
            }"
            @click="changeMobilemenu">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        <form
          :action="localePath('search')"
          method="get"
          class="search-form mt-4 px-3">
          <div class="input-group black-border w-full">
            <form-input
              :required="true"
              :placeholder="
                `${$t('components.search_box.search_like')}`
              "
              type="text"
              name="q"/>
            <div class="input-group__append">
              <button-primary
                :icon-prop="['fas', 'search']"
                :icon-size="'lg'"
                type="submit-form"
                :extra-class="'button--without-skew'">
              </button-primary>
            </div>
          </div>
        </form>
      </div>
      <div v-if="showMenu" class="mobile_menu">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full px-4">
              <ul style="padding-left: 0">
                <li>
                  <nuxt-link :to="localePath('index')" >
                    <font-awesome-icon :icon="['fas', 'home']" /> {{ $t('menu.home') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.about_us')"
                    :to="localePath('about-us')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.products')"
                    :to="localePath('products')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('components.new_products.header')"
                    :to="localePath('new-products')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.news')"
                    :to="localePath('news')"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-html="$t('menu.contact')"
                    :to="localePath('contact')"
                  />
                </li>
                <li>
                  <nuxt-link :to="localePath('account')" >
                    {{ $t('menu.account') }} <font-awesome-icon :icon="['fas', 'user']"/>
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="localePath('cart')" >
                    {{ $t('menu.cart') }}
                    <font-awesome-icon :icon="['fas', 'shopping-basket']"/>
                    <span
                      v-html="cartQty"
                      class="rounded-full px-2 bg-red text-white ml-1 text-sm"
                    />
                  </nuxt-link>
                </li>
                <li>
                  <font-awesome-icon :icon="['fas', 'phone']" flip="horizontal"/>
                  <a
                    v-html="$t('menu.phone_number_view')"
                    :href="$t('menu.phone_number')"
                    class="hover:text-black"
                  />
                </li>
                <li>
                  <img
                    @click="changeLanguage('en')"
                    src="~/assets/images/flags/en.png"
                    alt="English"
                    class="w-8 inline-block relative z-40"
                  />
                  <img
                    @click="changeLanguage('fr')"
                    src="~/assets/images/flags/fr.png"
                    alt="Français"
                    class="w-8 inline-block relative z-40"
                  />
                  <img
                    @click="changeLanguage('de')"
                    src="~/assets/images/flags/de.png"
                    alt="Deutsch"
                    class="w-8 inline-block relative z-40"
                  />
                  <img
                    @click="changeLanguage('ie')"
                    src="~/assets/images/flags/ie.png"
                    alt="Ireland"
                    class="w-8 inline-block relative z-40"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import portal from '../../service/portal';

export default {
  name: 'Header',
  props: {
    isMobile: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      activeMenu: '',
      isSearchAnimationDeleting: true,
      isSearchAnimationRunning: false,
      isViewLoaded: false,
      searchAnimationTxt: '',
      searchAnimationTxtCounter: 0,
      searchAnimationTxtFull: '',
      searchAnimationTxtPhrases: [],
      showMenu: false,
      searchAnimationTimeoutID: null
    }
  },
  computed: {
    cartQty() {
      return this.$store.state.cartQty
    },
    favoritesQty() {
      return this.$store.getters.getFavoriteItems.length;
    },
    isLoggedIn() {
      return this.$auth.loggedIn;
    }
  },
  watch: {
    $route(to, from) {
      this.showMenu = false
    },
    isMobile (newVal, oldVal) {
      if (!newVal && !this.isSearchAnimationRunning && this.isViewLoaded) {
        this.startAnimateSearchPlaceholder();
        return;
      }

      if (newVal) {
        this.stopAnimateSearchPlaceholder();
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$set(this, 'searchAnimationTxtFull', this.$t('components.search_box.search_like'));
      this.$set(this, 'isViewLoaded', true);
      this.startAnimateSearchPlaceholder();
    })
  },
  methods: {
    changeMobilemenu() {
      this.showMenu = !this.showMenu;
    },
    async changeLanguage(language) {
      await this.$store.dispatch('setLanguage', language).then(() => {
        if (!this.isLoggedIn) {
         this.$store.commit('REMOVE_ALL_CART_ITEM');
        }

        this.$cookies.set('language', language, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365
        })

        if (language !== 'en') {
          window.location.href = `/${language}`
          // this.$router.push({path: `/${language}`});
        } else {
          window.location.href = `/`
          // this.$router.push({path: `/`});
        }
      })
    },
    startAnimateSearchPlaceholder (firtsTimeout = 4000) {
      if (this.isMobile) {
        return;
      }

      this.$set(this, 'searchAnimationTxtCounter', 0);
      this.$set(this, 'searchAnimationTxtPhrases', [this.$t('components.search_box.search_like')]);
      if (this.$t('components.search_box.search_like_2')) {
        this.searchAnimationTxtPhrases.push(this.$t('components.search_box.search_like_2'))
      }
      if (this.$t('components.search_box.search_like_3')) {
        this.searchAnimationTxtPhrases.push(this.$t('components.search_box.search_like_3'))
      }

      this.$set(this, 'searchAnimationTxtFull', this.$t('components.search_box.search_like'));
      this.$set(this, 'searchAnimationTxt', this.$t('components.search_box.search_like'));
      this.$set(this, 'isSearchAnimationDeleting', true);
      this.$set(this, 'isSearchAnimationRunning', true);

      this.animateSearchPlaceholder(true, firtsTimeout);
    },
    animateSearchPlaceholder (isFirstRun = false, animationToumeout) {
      if (this.isMobile || !this.isSearchAnimationRunning) {
        return;
      }

      let timeoutValue = animationToumeout || 80;
      if (!isFirstRun) {
        let currentTxt = this.searchAnimationTxt;
        if (this.isSearchAnimationDeleting) {
          currentTxt = this.searchAnimationTxtFull.substring(0, this.searchAnimationTxt.length - 1);
        } else {
          currentTxt = this.searchAnimationTxtFull.substring(0, this.searchAnimationTxt.length + 1);
        }

        this.$set(this, 'searchAnimationTxt', currentTxt);

        if (!currentTxt.length) {
          this.$set(this, 'isSearchAnimationDeleting', false);
          timeoutValue = animationToumeout || 600;
          this.$set(this, 'searchAnimationTxtCounter', (this.searchAnimationTxtCounter + 1) % this.searchAnimationTxtPhrases.length);
          this.$set(this, 'searchAnimationTxtFull', this.searchAnimationTxtPhrases[this.searchAnimationTxtCounter]);
        } else if (currentTxt.length === this.searchAnimationTxtFull.length) {
          this.$set(this, 'isSearchAnimationDeleting', true);
          timeoutValue = animationToumeout || 3000;
        }
      }

      this.searchAnimationTimeoutID = setTimeout(() => {
        this.animateSearchPlaceholder();
      }, timeoutValue);
    },
    stopAnimateSearchPlaceholder () {
      this.$set(this, 'isSearchAnimationRunning', false);
      clearTimeout(this.searchAnimationTimeoutID);
    },
    searchBlurHandler (inputValue) {
      if (!inputValue || !inputValue.length && !this.isSearchAnimationRunning) {
        this.startAnimateSearchPlaceholder(1000);
      }
    }
  }
}
</script>

<style lang="scss">
.main_menu {
  a {
    @apply relative;
    &:after {
      @apply absolute w-4/5 border-b border-solid border-transparent;
      content: '';
      left: 10%;
      bottom: -5px;
    }

    &.nuxt-link-active {
      &:after {
        @apply border-white;
      }
    }
  }
}

.shrinked {
  .main_menu {
    a {
      &.nuxt-link-active {
        &:after {
          @apply border-black;
        }
      }
    }
  }
}

.header-desktop {
  display: block;
}
.header-mobile {
  display: none;
}

.search-form {
  .input-group {
    .form_input {
      border: none;
      padding-bottom: 0.3rem;
      padding-top: 0.3rem;
    }

    .input-group__append .inline > div {
      height: 100%;
    }

    button[type="submit"] {
      height: 100%;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

@media (max-width: 768px){
  .header-mobile {
    display: block;
  }
  .header-desktop {
    display: none;
  }
}

@media only screen and (max-width: theme('screens.phone')) {
  .search-form {
    .input-group {
      .form_input {
        @apply text-sm;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      button[type="submit"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}
</style>
